<template>
    <router-link :to="{ name: linkTo }" :class="[content.title, 'card card-basic']" :style="backgroundImage">
        <div class="card-detail">
            <span class="title">{{ content.title }}</span>
        </div>
        <div class="btn btn-link btn-back">
            <img src="/assets/icons/right-arrow.png" alt="Pfeil nach rechts">
        </div>
    </router-link>
</template>

<script>

import { getPlaceholderImage } from '@/utils/helpers';

export default {
    name: "CardCategory",
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    computed: {
        backgroundImage() {
            switch (this.content.title) {
                case 'Karte':
                case 'Orte':
                    return "background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), url('/assets/images/Orte.jpg');"
                    break;
                case 'Kalender':
                case 'Events':
                    return "background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), url('/assets/images/Events.jpg');"
                    break;
                case 'KulTouren':
                    return "background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), url('/assets/images/KulTouren.jpg');"
                    break;
                case 'Login':
                    return "background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), url('/assets/images/Login.jpg');"
                    break;
            }
        },
        linkTo() {
            switch (this.content.title) {
                case 'Karte':
                case 'Orte':
                    return 'Karte'
                    break;
                case 'Kalender':
                case 'Events':
                    return 'KulturKalender'
                    break;
                case 'KulTouren':
                    return "KulTouren";
                    break;
                case 'Login':
                    return "login"
                    break;
            }
        },
        image() {
            return getPlaceholderImage(this.content.label)
        },
        encodedValue() {
            return encodeURI(this.content.value);
        },
    },
    methods: {
        getPlaceholderImage
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card {
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    border: none;
    min-width: 150px;
    min-height: 180px;
    margin-bottom: 15px;
    box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        color: $primary;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.35);
    }

    .card-img {
        position: relative;
        max-height: 100px;
        min-height: 100px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .card-detail {
        padding: 10px 20px;

        .title {
            font-size: 20px;
            margin-bottom: 10px;
            color: #FFF;
            line-height: 1.25;
            font-family: 'elliot-bold', sans-serif;
            font-weight: 700;
        }
    }
}

.card-basic {
    background-size: cover;
    background-position: center;
}

.Login {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 20px;
    opacity: 0.8;

    &:hover,
    &:focus,
    &:active {
        background: #fff !important;
    }
}
</style>
