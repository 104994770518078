<template>
    <div class="row kultour-section">
        <div class="col-12 recommendations" v-if="kultourList != null && kultourList.length > 0">
            <div class="section-header">
                <h3 class="section-title">Wie wäre es mit etwas KulTour?</h3>
                <router-link to="/kul-touren" class="view-all-link">Alle KulTouren anzeigen</router-link>
            </div>
            <div class="row scroll-container">
                <div class="card-wrapper" v-for="touren in kultourList.slice(0, 4)" :key="touren.id">
                    <CardKultour :collection="touren" :marginRight="'0px'" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getContentsOfType } from '@/api/content';
import CardKultour from '@/components/cards/CardKultour.vue';

export default {
    name: 'KulTourSection',
    components: {
        CardKultour,
    },
    props: {
        touren: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            kultourDataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 4,
                    keyword: '',
                    type: 'view',
                    random: true,
                    selectedAppId: this.$appId,
                    view_status: 1,
                    selectedContentTypeId: this.$kultourId,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
        }
    },
    computed: {
        kultourList() {
            if (this.touren != null && this.touren.length > 0) {
                return this.touren;
            }
            else {
                return this.kultourDataSet.data;
            }
        }
    },
    methods: {
        async getKulTourList() {
            const { limit, page } = this.kultourDataSet.query;
            const { data, meta } = await getContentsOfType(this.$kultourId, this.kultourDataSet.query);
            this.kultourDataSet.data = data;
            this.kultourDataSet.data.forEach((element, index) => {
                element['index'] = (page - 1) * limit + index + 1;
            });
            this.kultourDataSet.meta = meta;
            this.kultourDataSet.total = meta.total;
        },
    },
    created() {
        this.getKulTourList();
    }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.section {
    background-color: transparent;
}

.main-section {
    padding: 0px 80px;
}

.institution-card {
    max-width: 31%;
}

.recommendations {
    @media (max-width: 1090px) and (min-width: 992px) {
        width: 100%;
    }
}

.container {
    @media (max-width: 992px) {
        max-width: 100%;
        padding: 0px 40px;
    }

    @media (max-width: 500px) {
        padding: 0px 20px;
    }
}

.scroll-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0px;
    width: auto;

    .card-wrapper {
        flex: 1 1 calc(25% - 16px);
        max-width: calc(25% - 16px);
        min-width: 0;
    }

    @media (max-width: 1250px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-right: 32px;

        .card-wrapper {
            flex: 0 0 calc(50% - 16px);
            max-width: calc(50% - 16px);
        }
    }

    @media (max-width: 731px) {
        .card-wrapper {
            flex: 0 0 calc(100% - 16px);
            max-width: calc(100% - 16px);
        }
    }
}

.card-wrapper {
    display: flex;
    width: 100%;
    max-width: none;

    :deep(.kultour-card) {
        width: 100%;
        height: 100%;
    }
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section-title {
        margin-bottom: 0;
    }

    .view-all-link {
        color: $secondary;
        text-decoration: underline;
        font-weight: 600;
        font-size: 16px;
        text-align: right;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}
</style>
<style lang="scss">
.kultour-section {
    .kultour-card {
        width: 100%;
        height: 100%;

        .card {
            margin-bottom: 0px !important;
        }
    }
}
</style>