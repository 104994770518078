<template>

    <ul class="navigation">
        <li title="Zu der Startseite">
            <img class="link-icon" src="/assets/icons/menu_Home.svg"><router-link to="/">Home</router-link>
        </li>
        <li title="Zu der KulturKarte">
            <img class="link-icon" src="/assets/icons/menu_Karte.svg"><router-link to="/karte">Orte</router-link>
        </li>
        <li title="Zu dem KulturKalender">
            <img class="link-icon" src="/assets/icons/menu_Kalender.svg"><router-link
                to="/kultur-kalender">Events</router-link>
        </li>
        <li v-if="false" title="Zu den KulturTouren">
            <img class="link-icon" src="/assets/icons/footprints_pink.svg" alt="KulTouren"><router-link
                to="/kul-touren">KulTouren</router-link>
        </li>
        <li title="Zu dem Verzeichnis A-Z">
            <img class="link-icon" src="/assets/icons/menu_Verzeichnis.svg"><router-link to="/verzeichnis">Verzeichnis
                A-Z</router-link>
        </li>
        <li v-if="isLoggedIn">
            <img class="link-icon" src="/assets/icons/menu_Person.svg" alt="Mein Profil"><router-link to="/profil"
                v-if="isLoggedIn">Mein Profil</router-link>
        </li>
        <li class="btn-search" title="Zu der Suche">
            <router-link to="/suche" class="btn btn-fill btn-search icon-before btn-focus-design">
                <i class="material-icons" aria-hidden="true">search</i>
                Suche ...
            </router-link>
        </li>
        <li class="auth-btns">
            <div v-if="isLoggedIn" title="Aus dem Benutzerkonto abmelden">
                <img class="link-icon" src="/assets/icons/menu_Lock.svg" alt="Abmelden">
                <a href="/" @click.prevent="logOut">
                    Abmelden
                </a>
            </div>
            <div v-else title="Zu der Anmeldung">
                <img class="link-icon" src="/assets/icons/menu_UnLock.svg" alt="Anmelden">
                <router-link to="/login">
                    Anmelden
                </router-link>
            </div>
        </li>
    </ul>

</template>

<script>
import { showLoader, hideLoader } from '@/utils/helpers';

export default {
    name: "NavLinks",
    data() {
        return {
            loader: null,
        }
    },
    computed: {
        isLoggedIn() {
            if (this.$store.getters.getToken) {
                return true;
            }
            return false;
        },
    },
    methods: {
        showLoader,
        hideLoader,
        logOut() {
            this.loader = this.showLoader(this.loader);
            this.$store.dispatch('logout');

            this.$notify({
                duration: 2500,
                title: 'Erfolgreich abgemeldet!',
                text: 'Sie haben sich vom KulturKompass Kiel abgemeldet.'
            });

            this.loader = this.hideLoader(this.loader);
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>
<style lang="scss">
@import '@/scss/_variables.scss';

header .mobile-nav .navigation li a {
    width: 100%;
}

.auth-btns .btn {
    background-color: #E30059;
    color: #fff;
    border: 2px solid #E30059;
    outline: none;
    box-shadow: none;
}

header .mobile-nav .navigation li a:not(.btn) {
    display: inline !important;
    vertical-align: middle;
}


header ul.navigation li img.link-icon {

    display: none;
    margin-right: 5px;
    vertical-align: middle;
    width: 24px;
    height: 24px;

    @media (max-width: 1200px) {
        display: inline;
        color: $secondary;
    }
}
</style>
